import clsx from 'clsx'
import React from 'react'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { Media } from '~/lib/Media'
import { Headline } from '~/elements/Headline/Headline'
import Copy from '../Header/components/Icons/Copy'
import { useTranslation } from 'next-i18next'
import { ImageOrVideo } from './components/ImageOrVideo'
import { MobileAsset } from './components/MobileAsset'
import Countdown from './components/Countdown'
import { StageComponentProps } from './NewStage'
import { ButtonCta } from '~/elements/Button/ButtonCta'

const PrevStage: React.FC<StageComponentProps> = ({
  id,
  data,
  mainStage,
  countdownData,
  margin,
  onNavItemClick,
  isPromoCodeCopied,
  copyToClipboard,
  stageLarge,
  children,
}) => {
  const { t } = useTranslation()

  if (!data?.stage) return null
  const {
    miniHeadline,
    headline,
    subline,
    darkText,
    image,
    imageMobile,
    video,
    videoMobile,
    cta,
    type,
    alignment,
    isFramed,
    halfWidthText,
    imageFocus,
    imageFocusMobile = imageFocus,
  } = data?.stage

  return (
    <section
      style={{ ...margin }}
      className={clsx(
        'relative z-10 flex h-[600px] w-full items-end overflow-hidden lg:items-center',
        !stageLarge && alignment === 'Right'
          ? 'justify-end'
          : !stageLarge && alignment === 'Left'
          ? 'justify-start'
          : 'justify-center',
        stageLarge && 'breakout-content-width',
        {
          'lg:h-[calc(90vh-123px)] lg:min-h-[calc(90vh-123px)] ':
            !stageLarge && !data?.stage?.countDown,
          'h-[503px] lg:h-[610px] ': !stageLarge && data?.stage?.countDown,
          'h-[90vh]': !mainStage && stageLarge,
          'sm:max-md:h-[400px]': !mainStage && !stageLarge,
          'sm:max-md:h-[500px]': mainStage && stageLarge,
          'sm:cursor-pointer':
            cta?.linkExistingPage?.slug || cta?.linkExternalPage || cta?.linkExternalPage,
        },
      )}
      onClick={onNavItemClick}
    >
      <Jumplink id={id} />
      <div
        className={clsx(
          'absolute h-full',
          !stageLarge && isFramed
            ? 'left-0 right-0 top-0 bottom-0 m-4 h-auto w-auto lg:m-20'
            : 'h-full w-full',
        )}
      >
        <div className="relative h-full w-full child:relative child:h-full child:w-full">
          <Media greaterThanOrEqual="lg">
            <ImageOrVideo
              video={video}
              image={image}
              mainStage={mainStage}
              imageFocus={imageFocus}
              type={type}
              cta={cta}
            />
          </Media>
          <Media lessThan="lg">
            <MobileAsset
              video={video}
              image={image}
              mainStage={mainStage}
              imageFocus={imageFocus}
              type={type}
              imageMobile={imageMobile}
              imageFocusMobile={imageFocusMobile}
              videoMobile={videoMobile}
            />
          </Media>
        </div>
      </div>
      <div
        className={clsx(
          data?.stage?.countDown
            ? 'flex h-full w-full items-center justify-center lg:relative lg:justify-start'
            : halfWidthText
            ? 'relative w-full'
            : 'flex h-full w-full items-center justify-center',
        )}
      >
        {!data?.stage?.countDown ? (
          <div
            className={clsx(
              'pb-[50px] lg:pb-0',
              halfWidthText
                ? 'TEST z-10 w-[100%] max-w-[100vw] px-4 text-center lg:max-w-[50%] lg:px-[109px] lg:text-left'
                : 'TEST z-10 mt-14 max-w-[100vw] px-4 lg:mt-0 lg:max-w-[65%] lg:px-[109px]',
              !stageLarge && isFramed ? '!px-8 lg:mx-20' : '',
              !stageLarge && alignment === 'Right'
                ? 'text-left'
                : !stageLarge && alignment === 'Left'
                ? 'text-left'
                : 'text-center',
            )}
          >
            {miniHeadline ? (
              <Headline
                type={mainStage ? 'h2' : 'h3'}
                styleAs={'h3'}
                className={clsx(
                  '!text-[15px] lg:!text-[20px]',
                  darkText ? '' : '!text-primary-white',
                )}
              >
                {miniHeadline}
              </Headline>
            ) : null}
            <Headline
              type={mainStage ? 'h1' : 'h2'}
              styleAs={mainStage ? 'h1' : 'h2'}
              className={clsx('pb-4 lg:pb-6', darkText ? '' : '!text-primary-white')}
            >
              {headline}
            </Headline>
            {subline ? (
              <Headline
                type={mainStage ? 'h2' : 'h3'}
                styleAs={'subline-20'}
                className={clsx(darkText ? '' : '!text-primary-white')}
              >
                {subline}
              </Headline>
            ) : null}
            {cta && cta?.newCtaStyle && <ContentfulButton {...cta} />}
            {cta &&
              !cta?.newCtaStyle &&
              (!halfWidthText ? (
                <ContentfulButton
                  {...cta}
                  ctaStyle={stageLarge ? 'BigText' : 'MediumText'}
                  className={clsx(
                    'block pt-2 lg:pt-8 lg:!text-[20px]',
                    stageLarge ? '' : 'mt-2 lg:mt-4',
                    darkText ? '' : 'text-primary-white !decoration-primary-white',
                  )}
                />
              ) : (
                <div className="flex w-full justify-center lg:justify-start">
                  <ContentfulButton
                    {...cta}
                    ctaStyle="Tertiary"
                    className={clsx(
                      'mt-6 !w-[70%] !border-primary-white !text-primary-white !decoration-primary-white hover:!border-primary-white hover:!bg-primary-white hover:!text-primary-black lg:mt-10 lg:!w-[53%]',
                    )}
                  />
                </div>
              ))}
          </div>
        ) : (
          <div
            className={clsx(
              'z-10 w-full max-w-[393px] text-center lg:m-[80px] lg:max-w-[700px] lg:text-left',
            )}
          >
            {miniHeadline ? (
              <Headline
                type={mainStage ? 'h2' : 'h3'}
                styleAs={'subline-20'}
                className={clsx(darkText ? '' : '!text-primary-white')}
              >
                {miniHeadline}
              </Headline>
            ) : null}
            <Headline
              type={mainStage ? 'h1' : 'h2'}
              styleAs="h1"
              className={clsx(
                'pb-4 lg:pb-6',
                darkText ? '' : '!text-primary-white',
                miniHeadline ? 'mt-2' : '',
              )}
            >
              {headline}
            </Headline>
            {subline ? (
              <Headline
                type={mainStage ? 'h2' : 'h3'}
                styleAs={'subline-20'}
                className={clsx(darkText ? '' : '!text-primary-white')}
              >
                {subline}
              </Headline>
            ) : null}
            {!data?.stage?.countDown?.hidePromoCode && (
              <div
                className={clsx(
                  'mt-10 mb-6 flex w-full items-center justify-center lg:justify-start',
                  darkText ? '' : '!text-primary-white',
                )}
              >
                <span className="mr-3 text-[20px]">{t('WITH CODE')}:</span>
                <button
                  onClick={() => copyToClipboard()}
                  disabled={isPromoCodeCopied}
                  className="!h-[32px] !w-[155px] border text-center hover:border-primary-white hover:bg-primary-white hover:text-primary-black disabled:border-primary-black disabled:bg-primary-black disabled:text-primary-white lgx:border-[0.25px]"
                  data-promo-code
                >
                  <div className="pointer-events-none flex items-center justify-center px-[20px] py-[5px]">
                    <span className="text-[15px] leading-[22px]">
                      {!isPromoCodeCopied ? data?.stage?.countDown?.discountCode : t('CODE COPYED')}
                    </span>
                    {!isPromoCodeCopied && <Copy className="ml-2 h-4 w-4" />}
                  </div>
                </button>
              </div>
            )}
            {countdownData.isReady && (
              <div
                className={clsx(
                  'flex w-full items-center justify-center lg:justify-start',
                  darkText ? '' : '!text-primary-white',
                  !data?.stage?.countDown?.hidePromoCode ? '' : 'my-10 lg:mb-0 lg:mt-12',
                )}
              >
                <Countdown countdownData={countdownData} />
              </div>
            )}
            {cta && <ButtonCta cta={cta} className="mt-2" />}
          </div>
        )}
        {children}
      </div>
    </section>
  )
}

export default PrevStage
