import { useTranslation } from 'next-i18next'
import React from 'react'
import { BanderoleCountDown } from '~/hooks/useCountdownData'

const SingleDataBox: React.FC<{ number: string; label: string }> = ({ number, label }) => {
  return (
    <div className="p-1 text-center">
      <div className="h-[60px] w-[60px] rounded-full bg-[#EFEEE5] font-right-grotesk text-[20px] text-[#141414]">
        <div className="flex h-full w-full items-center justify-center font-bold">{number}</div>
      </div>
      <div className="mt-1 text-[12px] font-bold">{label}</div>
    </div>
  )
}

const Colon: React.FC = () => {
  return <div className="mx-1 mb-6 text-center font-right-grotesk text-[25px] font-bold">:</div>
}

const Countdown: React.FC<{ countdownData: Omit<BanderoleCountDown, 'expired'> }> = ({
  countdownData,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <SingleDataBox number={countdownData.days} label={t('Days')} />
      <Colon />
      <SingleDataBox number={countdownData.hours} label={t('Hours')} />
      <Colon />
      <SingleDataBox number={countdownData.minutes} label={t('Minutes')} />
      <Colon />
      <SingleDataBox number={countdownData.seconds} label={t('Seconds')} />
    </>
  )
}

export default Countdown
