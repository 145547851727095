import { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperType } from 'swiper'
import { Navigation, Pagination } from 'swiper'
import clsx from 'clsx'
import { Page, BlogCategory } from '@contentfulTypes'
import { Headline } from '~/elements/Headline/Headline'
import { Image } from '~/elements/Image/Image'
import { Link } from '~/elements/Link/Link'
import { SliderButton } from '~/elements/SliderButton/SliderButton'
import { HighlightItem } from './HighlightItem'
import { Media } from '~/lib/Media'
import { useMedia } from '~/hooks/useMedia'

type Props = {
  highlights: Page[]
}

export const BlogHighlightModule = ({ highlights }: Props) => {
  const isDesktop = useMedia('lg')
  const [{ isEnd, isBeginning }, setSwiperPositon] = useState({
    isEnd: false,
    isBeginning: true,
  })
  const swiperRef = useRef<SwiperType | null>(null)
  const firstHighlightCategories = highlights?.[0]?.contentModulesCollection?.items?.filter(
    (item) => (item as BlogCategory)['categoryName'],
  )
  const filteredHighlights = highlights.filter((highlight) => highlight.slug && highlight.stage)

  return (
    <aside>
      <Media greaterThanOrEqual="lg">
        {isDesktop ? (
          <div className="relative mb-12 hidden flex-col gap-4 lg:mb-20 lg:flex lg:flex-row lg:gap-8">
            {filteredHighlights?.[0]?.slug && filteredHighlights?.[0].stage?.image?.url ? (
              <HighlightItem
                blog={filteredHighlights?.[0]}
                categories={firstHighlightCategories as BlogCategory[]}
              />
            ) : null}
            <div className="flex flex-1 flex-col justify-between gap-6">
              {filteredHighlights?.map((highlight, i) => {
                const categories = highlight?.contentModulesCollection?.items?.filter(
                  (item) => (item as BlogCategory)['categoryName'],
                )

                if (i > 0 && i < 5 && highlight?.slug)
                  return (
                    <div key={highlight.sys.id}>
                      <Link
                        href={highlight?.slug}
                        className="group relative grid grid-cols-4 gap-6"
                      >
                        {highlight?.stage?.image?.url ? (
                          <div className="relative col-span-1 aspect-[4/3] overflow-hidden">
                            <Image
                              src={highlight?.stage?.image?.url}
                              alt={highlight?.stage?.image?.description ?? ''}
                              fill
                              sizes={`(min-width: 1024px) 15vw, 20vw`}
                              className="duration-500 ease-out group-hover:scale-105 "
                            />
                          </div>
                        ) : null}
                        <div className="col-span-3 self-center">
                          <div className="mb-2 flex gap-4">
                            {categories?.map((item, i) => (
                              <p className="text-grey-dark" key={item?.sys.id}>{`${
                                (item as BlogCategory)['categoryName']
                              }${i < categories.length - 1 ? ',' : ''}`}</p>
                            ))}
                          </div>
                          <Headline
                            className="inline-block underline decoration-transparent decoration-1 underline-offset-8 duration-300 group-hover:decoration-primary-black"
                            type="h4"
                            styleAs="slider"
                          >
                            {highlight?.stage?.headline}
                          </Headline>
                        </div>
                      </Link>
                    </div>
                  )
              })}
            </div>
          </div>
        ) : null}
      </Media>

      <Media lessThan="lg">
        {isDesktop ? null : (
          <div className="relative mb-10 w-[calc(100vw-32px)] max-w-max lg:hidden">
            <Swiper
              modules={[Navigation, Pagination]}
              slidesPerView={1}
              pagination={{
                clickable: false,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '"></span>'
                },
              }}
              centeredSlides
              allowTouchMove
              onBeforeInit={(swiper) => (swiperRef.current = swiper)}
              onInit={({ isBeginning, isEnd }) => setSwiperPositon({ isBeginning, isEnd })}
              onSlideChange={({ isBeginning, isEnd }) => setSwiperPositon({ isBeginning, isEnd })}
              className="pb-10"
            >
              {filteredHighlights?.map((item) => {
                return (
                  <SwiperSlide key={item?.sys.id}>
                    <HighlightItem
                      blog={item}
                      categories={firstHighlightCategories as BlogCategory[]}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
            {filteredHighlights?.length > 1 ? (
              <SliderButton
                isPrev
                onClick={() => swiperRef?.current?.slidePrev()}
                className={clsx(
                  isBeginning && 'cursor-default opacity-0',
                  'top-1/2 -left-3 -mt-10',
                )}
              />
            ) : null}
            {filteredHighlights?.length > 1 ? (
              <SliderButton
                onClick={() => swiperRef?.current?.slideNext()}
                className={clsx(
                  isEnd && 'cursor-default opacity-0',
                  isBeginning && '!opacity-100',
                  'top-1/2 -right-3 -mt-10',
                )}
              />
            ) : null}
          </div>
        )}
      </Media>
    </aside>
  )
}
