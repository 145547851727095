import { AccordionItem, AccordionButton, AccordionPanel } from '@reach/accordion'
import { animated, useSpring } from '@react-spring/web'
import { useElementSize } from '@charlietango/hooks'
import clsx from 'clsx'
import { Image } from '~/elements/Image/Image'

import { Headline } from 'elements/Headline/Headline'

export type IProps = {
  itemHeading: string
  children: React.ReactNode
  className?: string
}

export const CustomAccordionItem = ({ itemHeading, children, className }: IProps) => {
  const [panelRef, { height: panelHeight }] = useElementSize()

  const expand = useSpring({
    height: !!panelHeight ? Math.floor(panelHeight + 16) : panelHeight,
  })

  return (
    <AccordionItem className={clsx('overflow-hidden', className)}>
      <AccordionButton
        className={clsx(
          ` flex w-full items-center justify-between py-4 text-left duration-200 ease-in-out lg:py-6`,
        )}
      >
        <Headline type="h3" styleAs="p">
          {itemHeading}
        </Headline>
        <Image
          src="/images/Icons/chevron-down.svg"
          width={20}
          height={20}
          placeholder="empty"
          className={clsx(!!panelHeight && 'rotate-180', 'transition-all duration-200 ease-in-out')}
          alt="chevron-down-icon"
        />
      </AccordionButton>
      <animated.div style={expand} className="border-b border-grey-border">
        <div ref={panelRef}>
          <AccordionPanel>{children}</AccordionPanel>
        </div>
      </animated.div>
    </AccordionItem>
  )
}
