import { ReactNode } from 'react'
import clsx from 'clsx'
import { useMedia } from '~/hooks/useMedia'
import { Headline } from '~/elements/Headline/Headline'
import { Image } from '~/elements/Image/Image'
import { ContentfulButton } from '~/elements/Button/ContentfulButton'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'

type Props = {
  headline?: string | null
  subline?: string | null
  className?: string
  cta?: any
  icon?: any
  image?: any
  imageMobile?: any
  imageFocus?: string | null
  itemCount?: number
  itemIndex?: number
  darkText?: boolean | null
  hasBackgroundGradient?: boolean | null
  gradientDirection?: boolean | null
  isInProductSlider?: boolean | null
  metaContentType?: any
  metaContentName?: any
}

type WrapperProps = {
  children: ReactNode
  className?: string
  cta?: any
  isInProductSlider?: boolean | null
}

const LinkWrapper = ({ children, className, isInProductSlider, cta }: WrapperProps) => {
  const wrapperStyles = clsx(
    'relative flex h-full min-h-teaser-height-mobile items-end overflow-hidden lg:min-h-teaser-height',
    className,
    isInProductSlider ? 'w-[250px]' : 'w-full',
  )
  if (cta)
    return (
      <ContentfulButton noStyle {...cta} className={wrapperStyles}>
        {children}
      </ContentfulButton>
    )
  return <div className={wrapperStyles}>{children}</div>
}

export const TeaserItem = ({
  headline,
  subline,
  cta,
  className,
  icon,
  image,
  imageMobile,
  imageFocus,
  itemCount,
  itemIndex,
  darkText,
  hasBackgroundGradient,
  gradientDirection,
  isInProductSlider,
  metaContentType,
  metaContentName,
}: Props) => {
  const isDesktop = useMedia('lg')
  const { dataLayer } = useGTM()

  const onNavItemClick = () => {
    dataLayer(DataLayerEnum.EVENT, 'select_content', {
      dataLayer: {
        content_type: metaContentType?.dataLayerMetaContent,
        content_name: metaContentName?.dataLayerMetaContent,
      },
    })
  }

  return (
    <LinkWrapper className={className} isInProductSlider={isInProductSlider} cta={cta}>
      <div
        className={clsx('w-full', {
          'h-full transition duration-500 ease-out hover:scale-105': icon,
        })}
        onClick={onNavItemClick}
      >
        <div
          className={clsx(
            'absolute top-0 left-0 bottom-0 right-0 h-auto w-auto',
            !icon && 'transition duration-500 ease-out hover:scale-105',
          )}
        >
          <Image
            src={
              !isDesktop && imageMobile?.url ? (imageMobile?.url as string) : (image?.url as string)
            }
            alt={
              !isDesktop && imageMobile?.description
                ? (imageMobile?.description as string)
                : (image?.description as string)
            }
            fill
            sizes={`(min-width: 1024px) ${
              itemCount === 4 ? '25vw' : itemCount === 3 ? '34vw' : '50vw'
            },
            ${itemCount === 4 || (itemCount === 3 && itemIndex !== 0) ? '50vw' : '100vw'}`}
            style={{ objectPosition: imageFocus ? imageFocus : 'center', objectFit: 'cover' }}
          />
          {hasBackgroundGradient ? (
            <div className="pointer-events-none absolute inset-0 z-10 bg-sg-black/20" />
          ) : null}
        </div>
        <div
          className={clsx(
            'relative z-20 !w-full p-6',
            itemCount && itemCount > 2 ? 'text-center lg:p-12' : 'text-left lg:p-20',
            isInProductSlider ? 'text-center lg:px-8 lg:py-16' : '',
            icon ? 'h-full' : 'h-auto',
          )}
        >
          {icon ? (
            <div className="flex !h-[65px] w-full justify-center lg:!h-[100px]">
              <Image
                src={icon?.url}
                width={isDesktop ? icon?.width / 1.2 : icon?.width / 1.6}
                height={isDesktop ? icon?.width / 1.2 : icon?.height / 1.6}
                alt={''}
              />
            </div>
          ) : null}
          <div className={clsx({ 'h-3/6 lg:h-4/6': icon })}>
            {headline ? (
              <Headline
                className={clsx(
                  'mb-2 lg:mb-4',
                  !darkText && '!text-primary-white',
                  isInProductSlider && 'font-right-grotesk',
                  itemCount && itemCount <= 2 && 'lg:!text-[40px]',
                )}
                type={isInProductSlider ? 'h2' : 'h3'}
                styleAs={itemCount && itemCount >= 3 ? 'slider' : 'h4'}
              >
                {headline}
              </Headline>
            ) : null}
            {subline ? (
              <Headline
                styleAs="subline-20"
                className={clsx(
                  isInProductSlider ? 'mb-6' : 'mb-2 lg:mb-4',
                  !darkText && '!text-primary-white',
                )}
                type={isInProductSlider || (!isDesktop && itemCount && itemCount < 3) ? 'h3' : 'h5'}
              >
                {subline}
              </Headline>
            ) : null}
          </div>
          {cta ? (
            <p
              className={clsx(
                'inline-block text-center text-15-15-sg underline underline-offset-4',
                !darkText && 'text-primary-white',
                icon && 'mt-6 lg:mt-0',
              )}
            >
              {cta.ctaText}
            </p>
          ) : null}
        </div>
      </div>
    </LinkWrapper>
  )
}
