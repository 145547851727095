import clsx from 'clsx'
import { useTrail, a } from '@react-spring/web'

type Props = {
  text: string
  inView: boolean
}

export const BackgroundText = ({ text, inView }: Props) => {
  const letterArray = Array.from(text)

  const trail = useTrail(letterArray.length, {
    config: { mass: 5, tension: 1700, friction: 200 },
    opacity: inView ? 1 : 0,
    x: inView ? 0 : 100,
    from: { opacity: 0, x: 100 },
  })

  return (
    <div
      id="text"
      className={clsx(
        'breakout-content-width duration-600 !ml-0 flex overflow-y-visible overflow-x-scroll pl-4 leading-snug delay-300 duration-500 lg:col-span-3 lg:overflow-x-clip lg:pl-0 lg:leading-none',
      )}
    >
      {trail.map((style, index) => (
        <a.h2 key={index} style={style} className="font-right-grotesk text-[100px] lg:text-[350px]">
          {letterArray[index]}
        </a.h2>
      ))}
    </div>
  )
}
