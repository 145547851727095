import TextImageTeaser from 'modules/TextImageTeaser/TextImageTeaser'
import TextTeaser from 'modules/TextTeaser/TextTeaser'
import SeoTextModule from 'modules/SeoTextModule/SeoTextModule'
import VideoModule from 'modules/VideoModule/VideoModule'
import Stage from 'modules/Stage/Stage'
import Accordion from 'modules/Accordion/Accordion'
import Quote from 'modules/Quote/Quote'
import LogoGrid from 'modules/LogoGrid/LogoGrid'
import ImageModule from 'modules/ImageModule/ImageModule'
import TeaserModule from 'modules/TeaserModule/TeaserModule'
import EditorialGallery from 'modules/EditorialGallery/EditorialGallery'
import NewsletterModule from 'modules/NewsletterModule/NewsletterModule'
import ShopTheLook from 'modules/ShopTheLook/ShopTheLook'
import RecommendedProducts from 'modules/RecommendedProducts/RecommendedProducts'
import ProductTeaser from 'modules/ProductTeaser/ProductTeaser'
import BlogOverviewModule from '~/modules/BlogOverview/BlogOverviewModule'
import TabModule from '~/modules/TabModule/TabModule'
import InstagramModule from 'modules/InstagramModule/InstagramModule'
import Lookbook from '~/modules/Lookbook/Lookbook'
import BlackFridayNewsletterModule from '~/modules/BlackFridayNewsletterModule/BlackFridayNewsletterModule'
import HiddenH1 from '~/modules/HiddenH1/HiddenH1'

const components: { [key: string]: { component: React.ComponentType<any> } } = {
  textimageteaser: {
    component: TextImageTeaser,
  },
  textteaser: {
    component: TextTeaser,
  },
  seotextmodule: {
    component: SeoTextModule,
  },
  video: {
    component: VideoModule,
  },
  stage: {
    component: Stage,
  },
  accordion: {
    component: Accordion,
  },
  quote: {
    component: Quote,
  },
  logogrid: {
    component: LogoGrid,
  },
  imagemodule: {
    component: ImageModule,
  },
  teasermodule: {
    component: TeaserModule,
  },
  editorialgallery: {
    component: EditorialGallery,
  },
  newslettermodule: {
    component: NewsletterModule,
  },
  shopthelook: {
    component: ShopTheLook,
  },
  productsslider: {
    component: RecommendedProducts,
  },
  productteaser: {
    component: ProductTeaser,
  },
  blogoverviewmodule: {
    component: BlogOverviewModule,
  },
  tabmodule: {
    component: TabModule,
  },
  instagrammodule: {
    component: InstagramModule,
  },
  blackfridaynewslettermodule: {
    component: BlackFridayNewsletterModule,
  },
  lookbook: {
    component: Lookbook,
  },
  hiddenheadingh1: {
    component: HiddenH1,
  },
}

const selectModule = (name: string) => {
  return components[name]?.component
}

export default selectModule
