import { Asset, BlogCategory } from '@contentfulTypes'
import { Headline } from '~/elements/Headline/Headline'
import { Image } from '~/elements/Image/Image'
import { Link } from '~/elements/Link/Link'

type Props = {
  headline: string | null
  url?: string | null
  backgroundImage?: Asset | null
  imageFocus?: string
  backgroundImageMobile?: Asset
  imageFocusMobile?: string
  darkText?: boolean
  backgroundGradient?: boolean
  blogCategoriesCollection?: BlogCategory[]
}

export const BlogTeaserItem = ({
  headline,
  url,
  backgroundImage,
  blogCategoriesCollection,
}: Props) => {
  return (
    <Link href={url as string} title="title">
      <>
        <div className="relative aspect-[4/3] w-full overflow-hidden">
          {backgroundImage?.url ? (
            <Image
              src={backgroundImage?.url}
              alt={backgroundImage?.description ?? ''}
              fill
              sizes={`(min-width: 1024px) 25vw, 50vw`}
              className="max-w-full duration-500 ease-out group-hover:scale-105"
              style={{ objectFit: 'cover' }}
            />
          ) : null}
        </div>
        <div className="mt-3">
          <div className="flex gap-2">
            {blogCategoriesCollection?.map((category, i) => (
              <p className="text-grey-dark" key={category?.sys?.id}>{`${category['categoryName']}${
                i < blogCategoriesCollection.length - 1 ? ',' : ''
              }`}</p>
            ))}
          </div>
          <Headline
            className="mt-2 inline-block underline decoration-transparent decoration-1 underline-offset-8 duration-300 group-hover:decoration-primary-black"
            type="h4"
            styleAs="slider"
          >
            {headline}
          </Headline>
        </div>
      </>
    </Link>
  )
}
