import { useQuoteQuery } from '@contentfulTypes'
import { Headline } from 'elements/Headline/Headline'
import { Image } from '~/elements/Image/Image'
import tailwindConfig from 'theme'
import { useInView } from 'react-intersection-observer'
import { useSpring, easings, animated } from '@react-spring/web'
import { useMedia } from 'hooks/useMedia'
import clsx from 'clsx'
import { StaticShape } from '~/elements/OrganicShape/StaticShape'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { OrganicShape } from '~/elements/OrganicShape/OrganicShape'
import { useState } from 'react'
import { DEFAULT_LOCALE } from '~/config/constants'
import { useRouter } from 'next/router'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const Quote = ({ id }: Props) => {
  const [isAnimatedShape, setIsAnimatedShape] = useState(false)
  const preview = usePreviewMode()
  const contentfulClient = createGraphQLClient({ preview })
  const router = useRouter()
  const { data } = useQuoteQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.quote?.marginBottom as string,
    data?.quote?.marginBottomMobile as string,
  )
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })
  const isDesktop = useMedia('lg')
  const blowUp = useSpring({
    config: { easing: easings.easeOutBounce, mass: 10, tension: 100, friction: 40 },
    transform: isDesktop
      ? inView
        ? 'scale(1.2) translateY(-50%)'
        : 'scale(0) translateY(-50%)'
      : inView
      ? 'scale(1.1) translateY(0)'
      : 'scale(0) translateY(0)',
    transformOrigin: 'center',
  })
  if (!data?.quote) return null
  const {
    isSimpleVariant,
    quoteText,
    quoteAuthorInfo,
    quoteAuthorName,
    image,
    organicShapeColor,
    sys,
  } = data.quote

  const color = organicShapeColor
    ? // @ts-ignore
      tailwindConfig.theme.colors.secondary[organicShapeColor]
      ? // @ts-ignore
        tailwindConfig.theme.colors.secondary[organicShapeColor]
      : tailwindConfig.theme.colors.secondary.sand
    : tailwindConfig.theme.colors.secondary.sand

  const SimpleVariant = () => {
    return (
      <section className="relative p-4 md:p-8 lg:p-20" style={margin}>
        <Jumplink id={id} />
        <figure>
          <div className="flex flex-col items-center">
            <blockquote className="text-center lg:max-w-[65%]">
              <Headline type="h3">{quoteText}</Headline>
            </blockquote>
            {quoteAuthorName && (
              <figcaption>
                <p className="text-center">{`${quoteAuthorName}${
                  quoteAuthorInfo ? ` - ${quoteAuthorInfo}` : ''
                }`}</p>
              </figcaption>
            )}
            <Image
              src="/images/Icons/quote-straight.svg"
              width={87}
              height={50}
              placeholder="empty"
              alt="usp-icon"
            />
          </div>
        </figure>
      </section>
    )
  }

  const DetailedVariant = () => {
    return (
      <section
        ref={inViewRef}
        className={
          'relative flex h-full w-full max-w-[100vw] flex-col items-center overflow-visible p-4 pt-24 pb-10 md:flex-row md:p-8 md:pb-28 md:pt-24 lg:px-20 lg:py-32 lgx:py-48'
        }
        style={margin}
      >
        <Jumplink id={id} />
        <div className="relative h-full w-full md:w-1/2 md:max-w-3xl">
          {isAnimatedShape ? (
            <OrganicShape
              pathKey={sys.id}
              animatedProps={blowUp}
              className="left-1/5 absolute top-0 -z-10 h-full w-full md:left-1/4 lg:left-1/3"
              color={color}
            />
          ) : (
            <animated.div
              className={clsx(
                'absolute left-0 -top-[15%] -z-10 h-full w-full md:-top-[28%] md:left-[6%] lg:top-[28%]',
              )}
              style={blowUp}
            >
              <StaticShape color={color} />
            </animated.div>
          )}
          <div className=" pointer-events-none relative h-full w-full">
            <Image
              className="max-w-full"
              src={image?.url as string}
              alt={image?.description as string}
              sizes="50vw"
              width={image?.width as number}
              height={image?.height as number}
            />
            <Image
              src="/images/Icons/quote-curvy.svg"
              width={126}
              height={94}
              className={clsx(
                'absolute -top-16 left-10',
                isDesktop ? '-top-16 left-10' : '-top-10 left-6',
              )}
              style={{
                color: 'white',
                transform: 'rotate(0.5turn)',
              }}
              placeholder="empty"
              alt="usp-icon"
            />
          </div>
        </div>

        <div
          className={
            'relative z-10 mt-4 h-full w-full md:mt-0 md:ml-8 md:w-1/2 lg:my-auto lg:ml-20 lg:grid'
          }
        >
          <div className="relative h-full w-full">
            <Image
              src="/images/Icons/quote-curvy.svg"
              width={126}
              height={94}
              className={clsx(
                'absolute bottom-0 right-0 -z-10 md:-bottom-10 lg:-bottom-24',
                !quoteAuthorName && '-bottom-10',
              )}
              style={{ color: color }}
              placeholder="empty"
              alt="usp-icon"
            />
            <blockquote>
              <p className="mb-4">{quoteText}</p>
            </blockquote>
            {quoteAuthorName && (
              <figcaption>
                <Headline type="h2" styleAs="h1" className="">
                  {quoteAuthorName}
                </Headline>
                {quoteAuthorInfo ? (
                  <p className="mt-2 text-h4-mobile font-medium lg:text-h4-desktop">
                    {quoteAuthorInfo}
                  </p>
                ) : null}
              </figcaption>
            )}
          </div>
        </div>
      </section>
    )
  }

  if (isSimpleVariant) {
    return SimpleVariant()
  }
  return DetailedVariant()
}

export default Quote
