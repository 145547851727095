import clsx from 'clsx'
import tailwindConfig from 'theme'
import { createGraphQLClient } from 'graphql/contentfulClient'
import { useTranslation } from 'next-i18next'
import { useNewsletterModuleQuery } from '@contentfulTypes'
import { useMarginMaker } from 'hooks/useMarginMaker'
import { useMedia } from 'hooks/useMedia'
import { useSpring, easings, animated } from '@react-spring/web'
import { useInView } from 'react-intersection-observer'
import { usePreviewMode } from '~/hooks/usePreviewMode'
import { DEFAULT_LOCALE, EMAIL_PATTERN } from '~/config/constants'

import { Headline } from 'elements/Headline/Headline'
import { Image } from '@next/image'
import { StaticShape } from '~/elements/OrganicShape/StaticShape'
import { useRouter } from 'next/router'
import { Jumplink } from '~/elements/Jumplink/Jumplink'
import { NewsletterForm } from './NewsletterForm'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'

type Props = {
  id: string
}

const NewsletterModule = ({ id }: Props) => {
  const { t } = useTranslation()
  const isDesktop = useMedia('lg')
  const preview = usePreviewMode()
  const router = useRouter()
  const contentfulClient = createGraphQLClient({ preview })
  const { data } = useNewsletterModuleQuery(
    contentfulClient,
    {
      id,
      preview,
      locale: router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE,
    },
    { enabled: !!id },
  )
  const margin = useMarginMaker(
    data?.newsletterModule?.marginBottom as string,
    data?.newsletterModule?.marginBottomMobile as string,
  )

  const { ref: inViewRef, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const blowUp = useSpring({
    config: { easing: easings.easeOutBounce, mass: 10, tension: 100, friction: 40 },
    transform: isDesktop
      ? inView
        ? 'scale(1.2) translateY(-50%)'
        : 'scale(0) translateY(-50%)'
      : inView
      ? 'scale(1.1) translateY(0)'
      : 'scale(0) translateY(0)',
    transformOrigin: 'center',
  })

  if (!data?.newsletterModule) return null
  const {
    headline,
    headlineStyle,
    subline,
    image,
    imageMobile,
    hasOrganicShape,
    organicShapeColor,
    disclaimer,
    disclaimerRte,
  } = data?.newsletterModule

  const color = organicShapeColor
    ? // @ts-ignore
      tailwindConfig.theme.colors.secondary[organicShapeColor]
      ? // @ts-ignore
        tailwindConfig.theme.colors.secondary[organicShapeColor]
      : tailwindConfig.theme.colors.secondary.sand
    : tailwindConfig.theme.colors.secondary.sand

  return (
    <section
      className={clsx(
        'relative flex h-full w-full max-w-[100vw] flex-col items-center overflow-hidden p-4 pt-8 md:flex-row md:justify-center md:p-8 lg:px-20',
        hasOrganicShape ? 'pb-24 md:py-16 lgx:py-32' : 'pb-8',
      )}
      style={margin}
      ref={inViewRef}
    >
      <Jumplink id={id} />
      {image ? (
        <>
          <div
            className={clsx(
              'mt-4 h-full w-full md:mt-0 md:mr-8  md:max-w-3xl lg:my-auto',
              hasOrganicShape ? 'md:w-1/3 md:min-w-[24rem]' : 'md:w-1/2',
            )}
          >
            {headline ? (
              <Headline styleAs="h4" className="mb-6 px-4 lg:mb-10 lg:pr-16" type="h2">
                {headline}
              </Headline>
            ) : null}
            {subline ? (
              <p className={clsx('mb-6 px-4 text-h3-mobile lg:mb-10 lg:pr-20 lg:text-h2-mobile')}>
                {subline}
              </p>
            ) : null}
            <NewsletterForm disclaimer={disclaimer} disclaimerRte={disclaimerRte} />
          </div>
          <div
            className={clsx(
              'relative mb-8 h-full w-full md:order-2 md:mb-0 md:max-w-3xl',
              !hasOrganicShape ? 'order-first md:w-1/2' : 'mt-16 md:mt-0 md:w-2/3',
            )}
          >
            <Image
              className={clsx('max-w-full')}
              src={(imageMobile && !isDesktop ? imageMobile?.url : image?.url) as string}
              alt={
                (imageMobile && !isDesktop
                  ? imageMobile?.description
                  : image?.description) as string
              }
              sizes={isDesktop ? '50vw' : '100vw'}
              width={(imageMobile && !isDesktop ? imageMobile?.width : image?.width) as number}
              height={(imageMobile && !isDesktop ? imageMobile?.height : image?.height) as number}
            />
            {hasOrganicShape ? (
              <animated.div
                className={clsx(
                  'absolute left-0 -top-[15%] -z-10 h-full w-full md:-top-[18%] md:left-[6%] lg:top-[40%]',
                )}
                style={blowUp}
              >
                <StaticShape color={color} />
              </animated.div>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className={clsx('h-full w-full md:mr-12 md:w-1/2 md:max-w-[40rem] lg:mr-0')}>
            {headline ? (
              <Headline
                className={
                  headlineStyle === 'MediumText'
                    ? clsx('mb-6 px-4 !text-[32px] lg:mb-10 lg:pr-16')
                    : clsx(
                        'mb-6 px-4 !font-right-grotesk !text-[32px] !leading-[40px] lg:mb-10 lg:pr-16',
                      )
                }
                type={headlineStyle === 'MediumText' ? 'h2' : 'h2'}
              >
                {headline}
              </Headline>
            ) : null}
            {subline ? <p className={clsx('mb-6 px-4 lg:mb-10 lg:pr-20')}>{subline}</p> : null}
          </div>
          <div className={clsx('h-full w-full max-w-3xl md:w-1/2 lg:my-auto')}>
            <NewsletterForm disclaimer={disclaimer} disclaimerRte={disclaimerRte} />
          </div>
        </>
      )}
    </section>
  )
}

export default NewsletterModule
